<template lang="pug">
	//.container-xs-24-wrapper
		.container-xs-24.mb-6
			.wizard-wrap.mb-4
				h1.mb-3 {{ $t('h1.finance') }}
				.f-18.lh-22 {{ $t('finance.requisitesAdd')}} {{id}}

	.finance
		form-add-personal(v-if='me && item' :item='item')
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import FormAddShop from '@/components/Form/FormAddShop';
import AddPersonal from '@/components/Form/AddPersonal';

export default {
    name: 'ModalAddAccountStripe',
    components: {
        FormAddShop,
        'form-add-personal': AddPersonal,
    },

    props: ['id'],

    mounted() {},
    destroyed() {},

    data: () => ({
        item: null,
    }),

    async created() {
        this.getItem(this.id).then(v => {
            this.item = v;
        });
    },
    beforeDestroy() {},
    methods: {
        ...mapActions({
            addAccount: 'finance/addAccount',
            add: 'finance/addShop',
            getItem: 'finance/accountId',
        }),

        next() {
            this.$emit('formAddShopNext');
        },

        goToFinance() {
            this.$router.push({ name: 'finance' });
        },

        action(data) {
            data.accountId = this.item.id;

            return this.add(data)
                .then(i => {
                    this.$notify('success', this.$t('success.requisitesAdded'));
                    this.item.setShop(i);
                    setTimeout(() => this.goToFinance(), 1000);
                })
                .catch(v => {
                    this.$notify('error', v);
                });
        },
    },
    computed: {
        ...mapGetters({
            isPending: 'finance/isPending',
        }),
    },
    watch: {},
};
</script>
